import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';
import { v4 as getUUid } from 'uuid';
import { notification } from 'antd';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            edit_form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const route = useRoute();
              const lessons = _.map(ctx.get('@item.program_template_courses'), (lesson) => {
                return {
                  id: getUUid(),
                  course_template_id: _.get(lesson, 'course_template_id', ''),
                  name: _.get(lesson, 'course_template.name', ''),
                  photo_url: _.get(lesson, 'course_template.photo_url'),
                };
              });
              const form = useFormik({
                initialValues: {
                  user_id,
                  name: ctx.get('@item.name'),
                  description: ctx.get('@item.description'),
                  lessons: lessons,
                },
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      id: ctx.get('@item.id'),
                      set_program_template: {
                        name: values.name,
                        description: values.description,
                      },
                    };

                    const payload2 = {
                      id: ctx.get('@item.id'),
                      account_id: ctx.get('@item.account_id'),
                      lessons: _.map(values.lessons, (item) => {
                        return {
                          ...item,
                          user_id: user_id,
                          account_id: ctx.get('@item.account_id'),
                          template_id: ctx.get('@item.id'),
                          photo_url: _.get(item, 'photo_url'),
                        };
                      }),
                    };
                    const client = await getClient();
                    const template_data = await client.request(
                      gql`
                        mutation UpdateProgramTemplate(
                          $id: uuid!
                          $set_program_template: b2b_program_template_set_input = {}
                        ) {
                          program_template: update_b2b_program_template_by_pk(
                            pk_columns: { id: $id }
                            _set: $set_program_template
                          ) {
                            id
                          }
                        }
                      `,
                      { ...payload }
                    );

                    const edit_lesson = await client.request(
                      gql`
                        mutation UpdateProgramTemplateLesson(
                          $id: uuid!
                          $account_id: uuid!
                          $lessons: [b2b_program_template_lesson_insert_input!] = {}
                        ) {
                          delete_b2b_program_template_lesson(
                            where: { account_id: { _eq: $account_id }, template_id: { _eq: $id } }
                          ) {
                            affected_rows
                            returning {
                              account_id
                            }
                          }

                          lessons: insert_b2b_program_template_lesson(
                            objects: $lessons
                            on_conflict: { constraint: program_template_lesson_pkey, update_columns: id }
                          ) {
                            affected_rows
                            returning {
                              id
                            }
                          }
                        }
                      `,
                      { ...payload2 }
                    );

                    if (!!template_data && !!edit_lesson) {
                      route.navigate(
                        ctx.apply('routeStore.toUrl', 'programTemplateDetail', {
                          id: _.get(template_data, 'program_template.id', ''),
                        })
                      );
                    } else {
                      notification.error({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolError.default'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                canSubmit: form.isValid && form.dirty,
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        course_template_id: '',
                        id: getUUid(),
                        name: '',
                        photo_url: '',
                        description: '',
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },

                  reset: (field) => {
                    form.setFieldValue(`${field}.course_template_id`, '');
                    form.setFieldValue(`${field}.name`, '');
                    form.setFieldValue(`${field}.photo_url`, '');
                    form.setFieldValue(`${field}.description`, '');
                  },
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
